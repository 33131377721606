.animate {
  animation: scaleAnim 0.3s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
  @keyframes scaleAnim {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(2.25);
    }
  }
}

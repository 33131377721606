.loadingItem {
  display: grid;
  grid-template-rows: 1fr 72px;
  gap: 12px;
}

.skeletonImage {
  overflow: hidden;
  border-radius: 6px;
  height: max(150px, 10vw);
}

.infoRow {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
}

.textContainer {
  display: grid;
  grid-template-rows: 20px 20px;
  gap: 8px;
}

@use "sass:math";
@use "theme/src/colors";
@use "theme/src/layout";
@use "theme/src/screens";
@use "theme/src/text";
@use "./variables.module";

.navbar {
  // This keeps the navbar on top of the animating canvas when transitioning from miniplayer to featured.
  view-transition-name: navbar;
}

@media (max-width: calc(screens.$sm - 0.001em)) {
  .menuOpen {
    border-bottom-width: 1px;
    .menu {
      border-radius: 0 0 8px 8px;
      max-height: calc(100vh - variables.$height);
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0px; //unsure if this is the best way to hide the grey strip
      }
    }
  }

  .menuEnter,
  .menuExitActive,
  .menuExitDone {
    transform: translateY(-100%);
  }
  .menuEnterActive,
  .menuExitActive {
    transition: transform 400ms;
  }
  .menuEnterActive,
  .menuEnterDone {
    transform: translateY(0);
  }
}

/* 
References:
- https://codepen.io/FlorinPop17/pen/drJJzK
- https://codepen.io/ash_s_west/pen/GRZbvym
*/

@use "theme/src/colors";
.container {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
}

.container:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 9999px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.75s;
  box-shadow: 0 0 6px 16px white;
}

.container:active:after {
  box-shadow: 0 0 0 0 white;
  position: absolute;
  border-radius: 9999px;
  left: 0;
  top: 0;
  opacity: 1;
  transition: 0s;
}

.pulser {
  position: absolute;
  border-radius: 128px;
  background-color: transparent;
  animation: pulse-anim 2s infinite;
  inset: 0;
  opacity: 0;
  border-width: 2px;
  border-color: colors.$white;
  box-shadow: 0px 0px 16px colors.$white;

  @keyframes pulse-anim {
    0% {
      opacity: 0.3;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.3;
    }
  }
}

@use "theme/src/colors";
@use "theme/src/layout";
@use "theme/src/screens";
@use "theme/src/text";

.mainContent {
  flex: 1;
  display: flex;
  flex-direction: column;

  > *:not(style) ~ *:not(style) {
    margin-top: 32px;
  }
}

.spacesContainer {
  flex: 1;
  display: flex;
  margin-bottom: 64px;
  scroll-margin: calc(var(--navbar-height) + 32px);
  // used in space-container.scss
  view-transition-name: homepage-spaces-container;
  @media (min-width: screens.$sm) {
    scroll-margin: 32px;
  }
}

.titleContainer {
  margin: auto;
  padding: 40px 8px 10px 8px;

  .description {
    padding-top: 4px;
    font-size: text.rem(18px);
    line-height: 145%;
    letter-spacing: -0.01em;
    text-align: center;

    color: colors.$darker-gray;
  }
}

// Needed to prevent ghosting of this element during transitions
.featuredBg {
  view-transition-name: homepage-featured-bg;
}

@use "sass:math";

@use "./colors";

/// Removes the unit from a given value. If the given value is not a number it
/// will be returned as-is.
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return math.div($number, $number * 0 + 1);
  }

  @return $number;
}

/// Converts given em value to px assuming 1em = 16px
/// @param {Number} $number - value in em
/// @return {Number} - value in px
@function em-to-px($number) {
  @return strip-unit($number) * 16 + 0px;
}

/// Adapted from https://css-tricks.com/snippets/sass/custom-scrollbars-mixin/ and https://css-tricks.com/the-current-state-of-styling-scrollbars/
@mixin scrollbars(
  $width: 12px,
  $height: auto,
  $border-radius: 12px,
  $foreground-color: colors.$light-gray-on-white,
  $background-color: transparent,
  $ff-width: auto
) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $width;
    height: $height;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: $border-radius;

    border-right: 1px solid $background-color;
    border-left: 1px solid $background-color;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
    border-radius: $border-radius;
  }

  // Firefox support, see https://css-tricks.com/almanac/properties/s/scrollbar-color/
  // Firefox does not support width as length correctly
  scrollbar-width: $ff-width;
  scrollbar-color: $foreground-color $background-color;
}

/// Hide scrollbar but keeps functionality
@mixin hide-scrollbar {
  // Firefox
  scrollbar-width: none;
  // /IE 10+
  -ms-overflow-style: none;

  // Webkit
  &::-webkit-scrollbar {
    display: none;
  }
}

@use "theme/src/colors";
@use "theme/src/layout";
@use "theme/src/screens";
@use "theme/src/text";
@use "theme/src/util";
@use "../mixins.module.scss";

.rowContainer {
  overflow-x: auto;
  @include util.hide-scrollbar;
  @include mixins.responsiveNegativeMargins;
}

$row-column-gap: 8px;

.spaceRow {
  display: grid;
  grid-auto-flow: column; /* tells to add new children as new columns*/
  column-gap: $row-column-gap;
  grid-template-columns: minmax(292px, 1fr);
  grid-auto-columns: minmax(292px, 1fr);

  @media (min-width: screens.$lg) {
    grid-template-columns: minmax(320px, 1fr);
    grid-auto-columns: minmax(320px, 1fr);
  }

  @media (min-width: screens.$xxl) {
    grid-template-columns: 1fr;
    grid-auto-columns: minmax(1fr);
  }

  &:after {
    display: none;
    content: "";
    margin-left: -$row-column-gap;
    @media (min-width: screens.$sm) {
      display: block;
      width: layout.$section-padding-x-small;
    }

    @media (min-width: screens.$md) {
      width: layout.$section-padding-x-medium;
    }

    @media (min-width: screens.$lg) {
      width: layout.$section-padding-x-large;
    }
  }
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-auto-rows: min-content;
  gap: 18px 8px;

  @media (min-width: screens.$xs) {
    grid-template-columns: repeat(auto-fill, minmax(248px, 1fr));
    grid-auto-rows: min-content;
    gap: 18px 16px;
  }

  @media (min-width: screens.$md) {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    grid-auto-rows: min-content;
    gap: 24px 24px;
  }

  @media (min-width: screens.$xl) {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-auto-rows: min-content;
  }

  // Custom breakpoint, 5 columns above this size.
  @media (min-width: screens.$xxl) {
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: min-content;
  }
}

@mixin spaceListGrid($rows) {
  display: grid;
  grid-auto-rows: min-content;
  gap: 16px 8px;
  @include layout.spacesGridWithCutoff($rows, $rows, $rows, $rows, $rows);

  @media (min-width: screens.$xs) {
    gap: 16px 16px;
  }
}

.spacesListGridOneRow {
  @include spaceListGrid(1);
}

.spacesListGridTwoRows {
  @include spaceListGrid(2);
}

.spacesListGridThreeRows {
  @include spaceListGrid(3);
}

@use "theme/src/screens";
@use "theme/src/layout";

@mixin negativeMargins($padding) {
  margin-left: -$padding;
  width: calc(100% + 2 * $padding);
  padding-left: $padding;
  padding-right: $padding;
}

/**
* Use negative margins to bypass the padding on the container
*/
@mixin responsiveNegativeMargins {
  @include negativeMargins(layout.$section-padding-x-mobile);

  @media (min-width: screens.$sm) {
    @include negativeMargins(layout.$section-padding-x-small);
  }

  @media (min-width: screens.$md) {
    @include negativeMargins(layout.$section-padding-x-medium);
  }

  @media (min-width: screens.$lg) {
    @include negativeMargins(layout.$section-padding-x-large);
  }
}

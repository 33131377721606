@use "theme/src/screens";

.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // the exact same gradient copied from the Figma design
  // blending effect from the bottom and left to right linear gradient
  background: linear-gradient(0deg, #171717, transparent 40%),
    linear-gradient(90deg, rgba(0, 0, 0, 0.2) 8%, transparent 60%);
}

@use "./screens";
@use "./text";

// Bounds the maximum width of a container.
$max-container-width: 2400px;

$section-padding-x-mobile: 12px;
$section-padding-x-small: 24px;
$section-padding-x-medium: 48px;
$section-padding-x-large: 92px;

// header for homepage section, search section, "category" page
$section-header-font-size: text.rem(22px);

/* 
Renders a n x m grid of items that hides any excess items.
The maximum number of items shown is n * m. Surplus items are hidden.

Ex.
- gridWithCutoff(3, 1) will render a grid with 1 column and 1 row.
- if you try to render 20 items in the grid, only the first 3 will be shown.
- There is no need to filter the grid items in JS, they are hidden via CSS.

By default, uses equal sized columns. You can override this by specifying `grid-template-columns`
in the local class.
*/
@mixin gridWithCutoff($columns, $rows) {
  display: grid;
  grid-template-columns: repeat($columns, minmax(0, 1fr));
  & > *:nth-child(n + #{$columns * $rows}) {
    display: none;
  }
  & > *:nth-child(-n + #{$columns * $rows}) {
    display: inherit;
  }
}

/*
For constructing a spaces grid that hides excess items. The max number of columns is responsive to the screen size.
*/
@mixin spacesGridWithCutoff($rowsSmallest: 2, $rowsMobile: 2, $rowsSmall: 1, $rowsXLarge: 1, $rows2xLarge: 1) {
  @include gridWithCutoff(2, $rowsSmallest);

  @media (min-width: screens.$mobile) {
    @include gridWithCutoff(2, $rowsMobile);
  }

  @media (min-width: screens.$xs) {
    @include gridWithCutoff(3, $rowsSmall);
  }

  @media (min-width: screens.$md) {
    @include gridWithCutoff(4, $rowsXLarge);
  }

  @media (min-width: screens.$xl) {
    @include gridWithCutoff(5, $rows2xLarge);
  }
}

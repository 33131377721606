$foreground: hsl(var(--foreground));
$background: hsl(var(--background));

// Figma reference: https://www.figma.com/file/x105MEPt0PftFzb3rRVa6V/Spatial-Platform-2022?node-id:321-10351&t:cMkZwrUWGLYa41LI-0
// Spatial Palette
$black: #000;
$white: #fff;
$blue: #0077ff;
$red: #f33;
$almost-black: hsl(var(--almost-black));
$almost-white: hsl(var(--almost-white));
$gray-50: hsl(var(--gray-50));
$gray-100: hsl(var(--gray-100));
$gray-200: hsl(var(--gray-200));
$gray-300: hsl(var(--gray-300));
$gray-400: hsl(var(--gray-400));
$gray-500: hsl(var(--gray-500));
$gray-600: hsl(var(--gray-600));
$gray-700: hsl(var(--gray-700));
$gray-800: hsl(var(--gray-800));
$gray-900: hsl(var(--gray-900));
$gray-950: hsl(var(--gray-950));
$muted: hsl(var(--muted));
$muted-foreground: hsl(var(--muted-foreground));
$translucent-black: rgba(0, 0, 0, 0.3);

/*
* Avatar colors are intended be used as accents for
* differentiating users. Used in avatar backgrounds & coloring names
* in chat
*/
$avatar-blue: #2979ff;
$avatar-red: #ff5252;
$avatar-purple: "#bb00ff";
$avatar-teal: "#1ce9b6";
$avatar-orange: "#ff9100";
$avatar-green: "#81d758";
$avatar-pink: "#ff00c7";
$avatar-tan: "#ffeac7";

$avatar-light-blue: "#b3d6ff";
$avatar-light-purple: "#f1ccff";
$avatar-chat-pink: "#ffccf4";

// DEPRECATED: Non-palette colors, various colors that have been built up over time
// Do not use for new code unless absolutely necessary!

// Grayscale
$black--shadow: rgba($black, 0.24);
$black--shadow-light: rgba($black, 0.125);
$black--shadow-strong: rgba($black, 0.4);
$darkest-gray: #4c4c4c;
$darker-gray: #767676;
$dark-gray: #888;
$grey70: #828282;
$gray: #aaa;
$light-gray: #ccc;
$lighter-gray: #dadada;
$lighter-gray2: #eee;
$lightest-gray: #e2e2e2;
$gray-bg: #f7f7f7;
$transparent-gray: rgba(0, 0, 0, 0.25);
$transparent-gray2: rgba(0, 0, 0, 0.3);
$transparent-gray-bg: rgba(0, 0, 0, 0.4);
$transparent-white: rgba(255, 255, 255, 0.25);
// A shade of gray that passes contrast ratio accessibility tests when  used on a white background
$light-gray-on-white: rgba(0, 0, 0, 0.45);
$room-button-default-background: rgba($black, 0.25);
$room-button-default-foreground: $white;

// Other colors (not in the Spatial palette)
// Should eventually be migrated to a shared palette or removed
$teal: #00ffcc;
$teal--shadow: rgba($teal, 0.5);
$blue-light: #2288ff;
$blue--shadow: rgba(0, 56, 255, 0.24);
$blue--pulsate: rgba(34, 136, 255, 0.33);
$pale-blue: #bbddff;
$peach: #ffc3c3;
$purple: #8000ff;
$purple--shadow: rgba($purple, 0.24);
$yellow: #f7fc15;
$yellow--shadow: rgba($yellow, 0.5);
$green-active: #22dd88;
